import $ from "../_snowpack/pkg/jquery.js";
import {websitedomain, enablehcaptcha, disablelgin} from "./config.js";
if (disablelgin) {
  window.location.href = "/dashboard.html";
}
$("#button").click(function(e) {
  e.preventDefault();
  var username = $("#username").val();
  var password = $("#password").val();
  if (username != null) {
    if (username != "") {
      if (password != null) {
        if (password != "") {
          var url = "https://" + websitedomain + "/api/brett/login.php?user=" + username + "&pass=" + password + "&h-captcha-response=" + $("#token2").val();
          $.get(url, function(data, status) {
            if (data == '{"error":"1"}') {
              $(".submit").addClass("buttonan");
              console.log("not loggedin");
              setTimeout(() => {
                $(".submit").removeClass("buttonan");
              }, 2e3);
            } else {
              document.cookie = "sid=" + data;
              window.location.href = "/dashboard.html";
            }
          });
        }
      }
    }
  }
});
function main() {
  if (enablehcaptcha) {
    $(".hcaptcha").append('<div class="h-captcha" data-sitekey="43c50a77-7524-4433-8e27-2954cf9eadaa" data-callback="solvedCallback"></div>');
  }
}
main();
